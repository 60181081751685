<template>
   <div>
      <select class="form-select" v-model="$i18n.locale" @change="changeLanguage">
         <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.value">{{ lang.text }}</option>
      </select>
   </div>
</template>

<script>
export default {
   name: 'AmcSwitchLang',
   data() {
      return {
         langs: [
            { value: 'tr', text: '🇹🇷 Türkçe', img: 'tr-flag.svg' },
            { value: 'en', text: '🇺🇸 English', img: 'en-fleg.svg' },
         ],
      };
   },
   methods: {
      changeLanguage() {
         localStorage.setItem('lang', this.$i18n.locale);
         location.reload();
      },
   },
};
</script>

<style scoped></style>
